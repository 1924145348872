<template>
  <div class="calculator-container">
    <header class="header">
      <van-nav-bar
          title="装修计算器"
          @click-left="handleBlack"
          safe-area-inset-top
          v-if="showHeader"
      >
      </van-nav-bar>
<!--      <van-sticky :offset-top="50">-->
        <van-skeleton title avatar :row="3" v-if="showSkeleton"></van-skeleton>
        <div v-else class="card-box-cls">
          <div class="card-box">
            <div class="card-info" @click="handleViewUserCard">
              <div class="card-left">
                <img
                    :src="
                      userCard.headImgUrl
                      ? `${imgUrl}/${userCard.headImgUrl}`
                      : morenHeadImg
                  "
                    alt=""
                />
                <!-- <img src="../../assets/icons/mimi.jpg" alt=""> -->
                <div class="creater-info">
                  <div class="title">{{ userCard.name }}</div>
                  <div class="second">
                    {{ userCard.impressionLabel }}
                  </div>
                </div>
              </div>
              <div class="card-right" @click.stop="handleCall">
                <!-- <svg class="phone">
                    <use xlink:href="/phone.svg#phone-contract"></use>
                    </svg> -->
                <div class="phone">电话联系</div>
              </div>
            </div>
            <div class="synopsis">
              <span>{{ isFold ? foldSynopsisStr : synopsisStr }}</span>
              <span
                  style="color: #999"
                  @click="handleIsFold"
                  v-if="synopsisStr.length > 40"
              >{{ isFold ? "...展开" : "&lt;&lt;折叠" }}</span
              >
            </div>
          </div>
        </div>
<!--      </van-sticky>-->
    </header>
    <article class="calculator-article">

      <div class="calculator-article-content">
        <div class="title">
          <span></span>
          <p>你家的装修预算</p>
          <span></span>
        </div>
        <div class="calculator-screen">
          <div v-for="(it,index) in numList" :key="index">
            <div class="font-num" v-if="it!='.'">
              <div class="segment" :class="{c_clr:it==4||it==1}"></div>
              <div class="segment" :class="{c_clr:it==5||it==6}"></div>
              <div class="segment" :class="{c_clr:it==2}"></div>
              <div class="segment" :class="{c_clr:it==4||it==1||it==7}"></div>
              <div class="segment" :class="{c_clr:it==4||it==1||it==3||it==5||it==7||it==9}"></div>
              <div class="segment" :class="{c_clr:it==2||it==1||it==3||it==7}"></div>
              <div class="segment" :class="{c_clr:it==7||it==1||it==0}"></div>
            </div>
            <div v-if="it=='.'" class="point_cls"></div>
          </div>
          <span class="CNY_cls">元</span>
        </div>
        <div class="cost">
          <span>人工费: {{ laborCost }}元</span>
          <span>材料费: {{ materialCost }}元</span>
          <span>设计费: {{ designCost }}元</span>
          <span>质检费: {{ qualityCost }}元</span>
        </div>
        <div class="expense-inp">
          <div class="expense-inp-item">
            <div @click="handleChioceArea">{{ selectCity?selectCity:'选择所在区域' }}</div>
            <div @click="handleChioceType">{{ selectType?selectType:'选择房屋户型' }}</div>
          </div>
          <!--          <div>输入您的房屋面积</div>-->
          <div class="area">
            <van-field v-model="area" placeholder="输入您的房屋面积" type="number"/>
            <span class="unit">m²</span>
          </div>
        </div>
        <div>
          <van-button type="primary" color="#289FFF" round block @click="handleGetOffer">免费获取专属报价</van-button>
        </div>
      </div>
    </article>
    <van-popup v-model:show="showCityPicker" round position="bottom">
      <van-picker
          title="请选择所在城市区域"
          :columns="cityColumns"
          @cancel="showCityPicker = false"
          @confirm="onCityConfirm"
      />
      <!--      <van-cascader-->
      <!--          v-model="code"-->
      <!--          title="请选择所在地区"-->
      <!--          :options="options"-->
      <!--          :field-names="fieldNames"-->
      <!--      />-->
    </van-popup>
    <van-popup v-model:show="showTypePicker" round position="bottom">
      <van-picker item-height="40" title="请选择房屋户型" :columns="columns" @cancel="showTypePicker = false"  @confirm="onTypeConfirm"/>
    </van-popup>
    <van-overlay :show="showKnow" @click="showKnow = false">
      <div class="knowWrapper" @click.stop>
        <div class="knowBlock" >
          <p>预约报价成功，稍后将有专属客服</p>
          <p>为您提供免费的报价服务！</p>
          <p @click="closeMsg">知道了</p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {computed, onMounted, onUnmounted, reactive, toRefs} from "vue";
import {useRoute, useRouter} from "vue-router";
import {getSimpleUserCard} from "../../api/calculator";
import  {getCityCode} from '../../utils/city_code'
import {Dialog,Toast} from "vant";
import {initWechatShare} from "../../hooks/initWechatShare";
import {addClue} from "../../api/user/clue";
import { useWxAuthorization } from "@/hooks/wxAuthorization";
import {addClueBehaviorInfo} from "@/api/user/clue"
import { createConsult } from "@/api/user/userCard";
// import {addNum} from "../../api/case";
import $ from "jquery";
export default {
  name: "index",
  setup(){
    const route = useRoute()
    const router = useRouter()
    let internal = null;
    let seconds = 0;
    let showHeader = computed(() => route.query.hideTitleBar != 1);
    const merchantId = route.params.merchantId;
    const recommendClueId = route.params.recommendClueId?route.params.recommendClueId:''
    const state = reactive({
      showKnow:false,
      showSkeleton:false,
      synopsisStr: "",
      foldSynopsisStr: "",
      isFold: true,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      userCard:{},
      morenHeadImg: require("../../assets/icons/Vector.png"),
      imgSrc:'',
      qrCodeUrl:'',
      posterPic:require('../../assets/images/calculator_poster.png'),
      showOverlay:false,
      showImg:true,
      showTypePicker:false,
      columns:[
        {
          values: ['1室', '2室', '3室', '4室', '4室+'],
          // defaultIndex: 2,
        },
        {
          values: ['1厅', '2厅', '3厅','3厅+'],
          // defaultIndex: 1,
        },
        {
          values: ['1厨', '2厨', '3厨','3厨+'],
          // defaultIndex: 1,
        },
        {
          values: ['1卫', '2卫', '3卫','3卫+'],
          // defaultIndex: 1,
        },
        {
          values: ['1阳台', '2阳台', '3阳台','3阳台+']
          // defaultIndex: 1,
        },
      ],
      shareOptions: [],
      firstOptions: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
        { name: "分享海报", icon: require("@/assets/icons/share_poster.png")}
      ],
      posterOptions:[
        {name: "微信分享", icon: require("@/assets/icons/weChat2.png")},
        {name: "朋友圈分享", icon: require("@/assets/icons/friends2.png")},
      ],
      showSharePoster:false,
      showShare:false,
      code:'',
      selectCity:'',
      selectType:'',
      showCityPicker:false,
      numList:['','','','','','',''],
      laborCost:0,//人工费
      materialCost:0,//材料费
      designCost:0,//设计费
      qualityCost:0,//质检费
      area:'',
      cityColumns:[],
      options:[],
      openId:'',
      clueId:'',
      consultId: null,
    })
    const closeMsg = () =>{
      state.showKnow = false
    }
    //展开折叠
    const handleIsFold = () => {
      state.isFold = !state.isFold;
    };
    //电话
    const handleCall = () => {
      if (state.userCard.contactNum) {
        let a = document.createElement("a");
        a.setAttribute("href", `tel:${state.userCard.contactNum}`);
        a.click();
        countUserAction({clueId:state.clueId,openId:state.openId,type:2})
      } else {
        Dialog.alert({ title: "错误", message: "没有电话号码" });
      }
    };
    const handleViewUserCard = () => {
      let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
      router.push({
        path: `/wxShareUserCard/${route.params.clueShareSource}/${merchantId}/${recommendClueId}`,
        query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
      });
    };
    const loadCityCode = () =>{
      state.cityColumns = []
      let area = getCityCode()
      state.cityColumns = area
    }
    loadCityCode()
    const handleChioceType = ()=>{
      state.showTypePicker = true
    }
    const onTypeConfirm = (val) =>{
      console.log("选择类型",val)
      state.selectType = ''
      val.forEach(it=>{
        state.selectType += it
      })
      state.showTypePicker = false
    }
    const handleGetOffer = ()=>{
      // let p = {
      //   selectCity:state.selectCity,
      //   selectType:state.selectType,
      //   area:state.area,
      // }
      // addCalculatorClue()
      if (!state.selectCity||!state.selectType||!state.area){
        Toast.fail('请填写完整提交内容');
        return
      }else {
        let param = {
          type:2,
          busiId:2222,
          content:state.selectCity+';'+state.selectType+';'+state.area+'m²'
        };
        createConsult(param).then((data) => {
          if (data) {
            state.consultId = data.id;
            // state.addInfo.username = "";
            // state.addInfo.phone = "";
            // state.addInfo.content = "";
            // Toast.success("您的咨询已创建");
            countUserAction({clueId:state.clueId,openId:state.openId,type:9})
            state.showKnow = true
          }
        });
      }
      // console.log(p)
    }
    const handleChioceArea = ()=>{
      state.showCityPicker = true
    }
    const onCityConfirm = (val) =>{
      state.selectCity = ''
      console.log(val)
      val.forEach(it=>{
        state.selectCity += it.text
      })
      state.showCityPicker = false
    }
    const customCityFieldName = {
      text: 'cityName',
      children: 'cities',
    };
    const handleBlack = () => {
      // router.push({ path: "/" });
    };
    const changeNum = () =>{
      state.numList = []
      // let nums=Math.floor(Math.random()*(99999-1+1)+1);
      state.laborCost = Math.floor(Math.random()*(99999-1+1)+1);
      state.designCost = Math.floor(Math.random()*(99999-1+1)+1);
      state.materialCost = Math.floor(Math.random()*(99999-1+1)+1);
      state.qualityCost = Math.floor(Math.random()*(99999-1+1)+1);

      let nums = (state.laborCost +  state.designCost + state.materialCost + state.qualityCost)
      // let nums=(Math.random()*(9999999-1+1)+1).toFixed(2);
      let changeNums = nums.toString().split("")
      changeNums.forEach(it=>{
        state.numList.push(it)
      })
      // state.numList = JSON.parse(JSON.stringify(changeNums.split("")))
      // console.log( state.numList)
    }
    changeNum()
    const loadSetInterval = () =>{
      setInterval(()=>{
        changeNum()
      },2000)
    };
    loadSetInterval()
    const fieldNames = {
      text: 'name',
      value: 'code',
      children: 'items',
    };

    const getUserCard = async ()=>{
    let res = await getSimpleUserCard()
        console.log(res)
        if (res){
          state.userCard = res
          state.synopsisStr = res.selfIntro?res.selfIntro:'我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！';
          state.foldSynopsisStr = state.synopsisStr.substring(0, 44);
        }
    }
    getUserCard()

    const useWxAuth = async ()=>{
      let data= await  useWxAuthorization()
      if (data) {
        state.openId = data.openId;
      } else {
        state.openId = route.query.openId;
      }
      if (state.openId) {
        await getUserCard();
        await handleFirstAddClue()
        let temp = location.href.split("?")[0];
        if(recommendClueId){
          temp =temp.replace(recommendClueId,state.clueId)
        }else {
          temp = `${temp}/${state.clueId}`
        }
        countUserAction({clueId:state.clueId,openId:state.openId,type:1})
        let shareImg = 'https://wca.ujiaku.com/upload/tangYing/fitUp_calculator.png';
        // eslint-disable-next-line no-undef
        wx.miniProgram.getEnv(function(res) {
          if (res.miniprogram) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.postMessage({
              data: {
                title: state.userCard.name + '的装修计算器',
                img: shareImg,
                openId: state.openId,
                clueId: state.clueId,
                shareUrl:temp,
                type: 4
              },
            });
          } else {
            let title = state.userCard.name + '的装修计算器';
            let desc = state.userCard.name + '的装修计算器';
            let url = `${
                process.env.VUE_APP_WECHAT_SHARE
            }/shareForward?shareUrl=${temp}?appPhone=${localStorage.getItem(
                "appPhone"
            )}`;
            let imgUrl = shareImg;
            initWechatShare({ title, desc, url, imgUrl,openId:state.openId,clueId:state.clueId,type:4 });
          }
        });
      }
    }
    const handleFirstAddClue = async () => {
      let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
      let param = {
        shareId: state.userCard.loginUserId,
        clueSource: 9,
        browseSecond: 0,
        beSharePhone: miniProgramPhone,
        openId: state.openId,
        title: state.userCard.name + '的装修计算器',
        createId: state.userCard.loginUserId,
        consultId: state.consultId,
        clueShareSource: route.params.clueShareSource,
        recommend_Clue_Id:recommendClueId,
        merchantId
      };
      let data = await addClue(param);
      state.clueId = data;
      return data;
    };

    // const addCalculatorClue = () => {
    //   if (seconds > 0) {
    //     let miniProgramPhone = route.query.state
    //         ? route.query.state
    //         : route.query.miniProgramPhone;
    //     let param = {
    //       clueId: state.clueId,
    //       browseSecond: seconds,
    //       consultId: state.consultId,
    //       shareId: state.userCard.loginUserId,
    //       clueSource: 9,
    //       beSharePhone: miniProgramPhone,
    //       openId: state.openId,
    //       title:state.selectCity+';'+state.selectType+';'+state.area+'m²',
    //       // title: state.userCard.name + '的装修计算器',
    //       createId: state.userCard.loginUserId,
    //       clueShareSource: route.params.clueShareSource,
    //       recommend_Clue_Id:recommendClueId,
    //       merchantId
    //     };
    //     $.ajax({
    //       async: false,
    //       url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
    //       type: "post",
    //       contentType: "application/json;charset=UTF-8",
    //       data: JSON.stringify(param),
    //     });
    //     // seconds = 0;
    //     // clearInterval(internal);
    //   }
    // };

    const handleAddClue = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
            ? route.query.state
            : route.query.miniProgramPhone;
        let param = {
          clueId: state.clueId,
          browseSecond: seconds,
          consultId: state.consultId,
          shareId: state.userCard.loginUserId,
          clueSource: 9,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          // selectCity:state.selectCity,
          // selectType:state.selectType,
          // area:state.area,
          title: state.userCard.name + '的装修计算器',
          createId: state.userCard.loginUserId,
          clueShareSource: route.params.clueShareSource,
          recommend_Clue_Id:recommendClueId,
          merchantId
        };
        $.ajax({
          async: false,
          url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          type: "post",
          contentType: "application/json;charset=UTF-8",
          data: JSON.stringify(param),
        });
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleWindowHide = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
            ? route.query.state
            : route.query.miniProgramPhone;
        let param = {
          clueId: state.clueId,
          browseSecond: seconds,
          consultId: state.consultId,
          shareId: state.userCard.loginUserId,
          clueSource: 9,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          title: state.userCard.name + '的装修计算器',
          createId: state.userCard.loginUserId,
          clueShareSource: route.params.clueShareSource,
          recommend_Clue_Id:recommendClueId,
          merchantId
        };
        let blob = new Blob([JSON.stringify(param)], {
          type: "application/json",
        });
        navigator.sendBeacon(
            `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
            blob
        );
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleVisibilityStateChange = () => {
      if (document.visibilityState === "hidden") {
        handleAddClue();
        clearInterval(internal);
        seconds = 0;
      }
    };
    const countUserAction = async ({ clueId, openId, type }) => {
      /**
       * type:
       *  1查看
       *  2拨打
       *  3保存
       *  4转发
       *  5提交咨询
       *  6点赞
       *  7收藏
       *  8留言
       *  9提交装修报价
       */

      if (clueId) {
        await addClueBehaviorInfo({ clueId, openId, type });
      }
    };

    onMounted(() => {
      // addNum(route.params.id, "views");
      // loadHotArticle();
      internal = setInterval(() => {
        seconds++;
      }, 1000);
      document.addEventListener(
          "visibilitychange",
          handleVisibilityStateChange
      );
      window.addEventListener("pagehide", handleWindowHide, false);
    });
    onUnmounted(() => {
      clearInterval(internal);
      handleAddClue();
      document.removeEventListener(
          "visibilitychange",
          handleVisibilityStateChange
      );
      window.removeEventListener("pagehide", handleWindowHide, false);
    });
    useWxAuth()


    return{
      closeMsg,
      handleIsFold,
      handleCall,
      handleViewUserCard,
      handleChioceType,
      onTypeConfirm,
      handleGetOffer,
      fieldNames,
      handleChioceArea,
      customCityFieldName,
      onCityConfirm,
      changeNum,
      handleBlack,
      showHeader,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss">
.calculator-container{
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .van-nav-bar__text {
      color: #ff6e01;
    }
  }
  .van-ellipsis{
    font-size: 14px;
  }
  .expense-inp{
    .area{
      .van-field{
        background-color: #F7F9FC;
      }
      .van-field__control{
        color: #999999;
      }
    }
  }
  .van-share-sheet__cancel::before {
    height: 0;
  }
  .van-share-sheet__cancel {
    height: 48px;
    width: 343px;
    background-color: #f7f7f7;
    color: #333333;
    border-radius: 5px;
    //    position: relative;
    //    transform: translateX(50%);
    margin-left: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .van-share-sheet__options {
    color: #333333;
    display: flex;
    padding: 0 20px;
    justify-content: space-around;
  }
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      //    position: relative;
      //    transform: translateX(50%);
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
  .wrapperPoster{
    //margin-top: 100px;
    height: 400px;
    overflow-x: hidden;
    //position: absolute;
    position: fixed;
    top: 60px;
    //bottom: 0;
    left: 0;
    right: 0;
    .poster-details{
      background: url("../../assets/images/calculator_poster.png") no-repeat;
      background-size: cover;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      height: 360px;
      //overflow-x: hidden;
      >img{
        width: 250px;
        display: block;
      }
      .text-qrcode{
        background-color: #ffff;
        padding: 10px;
        p {
          margin: 0;
          padding: 0;
        }
        .left-text{
          margin-top: 20px;
          p:nth-child(1){
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            color: #333333;
          }
          p:nth-child(2){
            margin-top: 5px;
            font-size: 9px;
            color: #999999;
            line-height: 20px;
          }

        }

        width: 200px;
        height: 170px;
        margin-top: 160px;
        border-radius: 8px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .qrCodeUrl-all{
          //position: relative;
          //text-align: center;
          //height: 60px;
          .qrCodeUrl-cls{
            width: 70px;
            height: 70px;
            background-color: #FFFFFF;
            margin:  10px auto;
            border-radius: 35px;
            //position: relative;
            //top: -33px;
            background-color: #289fff;
            text-align: center;
            img{
              width: 70px;
              border-radius: 35px;
              position: relative;
              margin-top: 50%;
              transform: translateY(-50%);
            }
          }
          >p{
            //position: relative;
            margin: 0;
            padding: 0;
            font-size: 9px;
            font-weight: 400;
            color: #999999;
            //top: -40px;
          }
        }
      }

    }
    .posterImg-hb{
      height: 400px;
      overflow-x: hidden;
    }
  }
}
</style>
<style lang="scss" scoped>
.calculator-container{
  font-size: 16px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .knowWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .knowBlock {
    box-sizing: border-box;
    padding: 16px;
    border-radius: 8px;
    font-size: 14px;
    width: 303px;
    height: 130px;
    background-color: #fff;
    text-align: center;
    p:nth-child(3){
      color: #289fff;
    }
  }
  header{
    .card-box-cls {
      //background-color: #fff;
      //padding: 5px 16px 16px 16px;
      border-bottom:#999999 1px solid;
    }
    .card-box {
      // margin: 5px 16px 16px 16px;
      opacity:0.9;
      padding: 12px;
      border-radius: 8px;
      text-align: left;
      background-color: #fff;
      //box-shadow: 0px 0px 15px rgb(229, 229, 230);
      .synopsis {
        margin-top: 5px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.84);
      }
      .card-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .card-left {
          display: flex;
          img {
            height: 40px;
            width: 40px;
            border-radius: 20px;
          }
          .creater-info {
            margin-left: 8px;
            .title {
              font-weight: bold;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.84);
              margin-bottom: 3px;
            }
            .second {
              font-weight: normal;
              font-size: 11px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .card-right {
          .phone {
            width: 80px;
            height: 30px;
            background-color: #289fff;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .calculator-article{
    flex: 1;
    overflow-x: hidden;
    background: url("../../assets/images/calculator_bg.png") no-repeat;
    background-size: cover;

    .calculator-article-content{
      //height: 446px;
      width: 330px;
      background-color: #FFFFFF;
      margin: auto;
      margin-top: 180px;
      border-radius: 20px;
      padding: 16px;
      .title{
        //padding: 16px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        color: #333333;
        p{
          padding: 0;
          margin: 0 10px;
        }
        span{
          display: block;
          width: 56px;
          border-bottom: 2px #333333 solid;
        }
      }
      .calculator-screen{
        width: 324px;
        height: 60px;
        position: relative;
        background-color: #b4b4b4;
        border: 3px black solid;
        border-radius: 4px;
        margin-top: 16px;
        margin-right: 0px;
        display: flex;
        align-items:center;
        justify-content: flex-end;
        .font-num{
          width: 30px;
          height: 50px;
          display: flex;
          justify-content:center;
          align-items:center;
          //background-color: #FFFFFF;
          margin-right: 4px;
          .segment{
            position:absolute;
            width: 14px;
            height: 4px;
            border-radius: 4px;
            //background-color: #898989;
            background-color: black;
          }
          .segment:nth-child(1){
            transform: translateY(-16px);
            border-radius:0 0 4px 4px;
            //border-radius: 4px;
          }
          .segment:nth-child(2){
            transform:translate(8px,-8px) rotate(90deg);
            //transform: translateY(-11px);
            border-radius:0 0 4px 4px;
            width: 12px;
          }
          .segment:nth-child(3){
            transform:translate(8px,8px) rotate(90deg);
            border-radius:0 0 4px 4px;
            width: 12px;
          }
          .segment:nth-child(4){
            transform: translateY(16px);
            border-radius:4px 4px 0 0 ;
          }
          .segment:nth-child(5){
            transform:translate(-9px,8px) rotate(90deg);
            border-radius:4px 4px 0 0;
            width: 12px;
          }
          .segment:nth-child(6){
            transform:translate(-9px,-8px) rotate(90deg);
            width: 12px;
            border-radius:4px 4px 0 0;
          }
          .segment:nth-child(7){
            border-radius:2px 2px 2px 2px;
          }
          .c_clr{
            background-color: #adadad;
          }
        }
        .point_cls{
          height: 4px;
          width: 4px;
          background-color: black;
          position: relative;
          transform: translateY(16px);
          margin-right: 10px;
        }
        .CNY_cls{
          color: black;
          font-weight: 600;
          position: relative;
          top: 10px;
          right: 5px;
        }
      }
      .cost{
        font-size: 14px;
        font-weight: 600;
        padding: 16px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        span{
          display: block;
          width: 150px;
          text-align: left;
          padding-top: 16px;
        }
      }
      .expense-inp{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .expense-inp-item{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          div{
            height: 46px;
            width: 300px;
            background-color: #F7F9FC;
            border-radius: 4px;
            color: #999999;
            font-size: 14px;
            margin-bottom: 10px;
            padding-left:16px;
            line-height: 46px;
            text-align: left;
          }
        }
        .area{
          height: 46px;
          width: 316px;
          background-color: #F7F9FC;
          border-radius: 4px;
          color: #999999;
          font-size: 14px;
          margin-bottom: 10px;
          line-height: 46px;
          text-align: left;
          display: flex;
          justify-content: space-between;
          .unit{
            margin-right: 16px;
          }
        }
      }
    }
  }
}
</style>
